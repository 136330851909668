<template>
  <div class="main">
    <user-message />
    <map-container id="localities" :json-layers="layers" :json-features="featuresObject" />
    <div class="instruments">
      <div class="instruments-title">Приграничные<br />зоны РФ</div>
      <div v-if="features" class="instruments-features-fetched">
        Загружено<br />{{ features.features.length }} объектов
      </div>
      <file-loader-geojson @input="onLoadFeatures" style="margin-left: auto" />
      <sender-geojson :data="features" url="/backend/replace/custom/stateborderzones" />
      <g-navigation />
    </div>
  </div>
</template>

<script>
import GNavigation from '@/components/GNavigation'
import MapContainer from '@/components/mapcontainer/MapContainer'
import FileLoaderGeojson from '@/components/FileLoaderGeojson'
import SenderGeojson from '../components/SenderGeojson.vue'
import UserMessage from '../components/UserMessage.vue'

export default {
  name: 'StateBorders',
  components: {
    GNavigation,
    MapContainer,
    FileLoaderGeojson,
    SenderGeojson,
    UserMessage
  },
  data() {
    return {
      selection: [],
      selectedFeature: undefined,
      layers: {},
      features: undefined,
      disable: false
    }
  },
  computed: {
    featuresObject() {
      return {
        sourceName: 'CUSTOM.RESTRICTIVE.STATE_BORDER',
        features: this.features
      }
    }
  },
  created() {
    const sources = [
      {
        name: 'CUSTOM.RESTRICTIVE.STATE_BORDER',
        props: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        }
      }
    ]
    const layers = [
      {
        props: {
          id: 'CUSTOM.RESTRICTIVE.STATE_BORDER-outline',
          type: 'line',
          source: 'CUSTOM.RESTRICTIVE.STATE_BORDER',
          minzoom: 2,
          paint: {
            'line-opacity': ['interpolate', ['linear'], ['zoom'], 2, 0, 3, 0.5],
            'line-color': '#ef476f',
            'line-dasharray': [3, 1],
            'line-width': 1.3
          }
        }
      },
      {
        props: {
          id: 'CUSTOM.RESTRICTIVE.STATE_BORDER-fill',
          type: 'fill',
          source: 'CUSTOM.RESTRICTIVE.STATE_BORDER',
          minzoom: 2,
          paint: {
            'fill-opacity': ['interpolate', ['linear'], ['zoom'], 2, 0, 3, 0.1],
            'fill-color': '#ef476f'
          }
        },
        after: 'CUSTOM.RESTRICTIVE.STATE_BORDER-outline'
      }
    ]
    this.layers = { sources, layers }
  },
  methods: {
    onLoadFeatures(features) {
      this.features = features
    }
  }
}
</script>

<style lang="scss"></style>

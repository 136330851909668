export default {
  state: {
    token: sessionStorage.getItem('authentication-token') || ''
  },
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    clearToken(state) {
      state.token = ''
      sessionStorage.setItem('authentication-token', '')
    }
  },
  actions: {
    setToken(ctx, token) {
      sessionStorage.setItem('authentication-token', token)
      ctx.commit('setToken', token)
    },
    clearToken(ctx) {
      ctx.commit('clearToken')
    }
  },
  getters: {
    getToken(state) {
      return state.token
    }
  }
}

<template>
  <div class="main">
    <user-message />
    <map-container id="airportsheliports" :json-layers="layers" :json-features="featuresObject" />
    <div class="instruments">
      <div class="instruments-title">Аэродромы<br />вертодромы</div>
      <div v-if="features" class="instruments-features-fetched">
        Загружено<br />{{ features.features.length }} объектов
      </div>
      <file-loader-geojson @input="onLoadFeatures" style="margin-left: auto" />
      <sender-geojson :data="features" url="/backend/replace/custom/airports_heliports" />
      <g-navigation />
    </div>
  </div>
</template>

<script>
import GNavigation from '@/components/GNavigation'
import MapContainer from '@/components/mapcontainer/MapContainer'
import FileLoaderGeojson from '@/components/FileLoaderGeojson'
import SenderGeojson from '../components/SenderGeojson.vue'
import UserMessage from '../components/UserMessage.vue'

export default {
  name: 'AirportsHeliports',
  components: {
    GNavigation,
    MapContainer,
    FileLoaderGeojson,
    SenderGeojson,
    UserMessage
  },
  data() {
    return {
      layers: {},
      features: undefined
    }
  },
  computed: {
    featuresObject() {
      return {
        sourceName: 'CUSTOM.AIRPORTS_HELIPORTS',
        features: this.features
      }
    }
  },
  created() {
    const sources = [
      {
        name: 'CUSTOM.AIRPORTS_HELIPORTS',
        props: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        }
      }
    ]
    const layers = [
      {
        props: {
          id: 'CUSTOM.AIRPORTS.GEOJSON',
          type: 'symbol',
          source: 'CUSTOM.AIRPORTS_HELIPORTS',
          filter: ['==', ['get', 'type'], 'airport'],
          minzoom: 6,
          layout: {
            'symbol-z-order': 'viewport-y',
            'icon-image': 'airport-marker',
            'icon-size': 0.8,
            'text-field': ['get', 'name_ru'],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-size': 12,
            'text-offset': [0, 1.25],
            'text-anchor': 'top'
          },
          paint: {
            'icon-opacity': ['interpolate', ['linear'], ['zoom'], 6, 0, 8, 1],
            'text-opacity': ['interpolate', ['linear'], ['zoom'], 6, 0, 8, 1]
          }
        }
      },
      {
        props: {
          id: 'CUSTOM.HELIPORTS.GEOJSON',
          type: 'symbol',
          source: 'CUSTOM.AIRPORTS_HELIPORTS',
          filter: ['==', ['get', 'type'], 'heliport'],
          minzoom: 7.5,
          layout: {
            'symbol-z-order': 'viewport-y',
            'icon-ignore-placement': true,
            'icon-image': 'heliport-marker',
            'icon-size': 0.4,
            'text-field': ['get', 'name_ru'],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-size': 10,
            'text-offset': [0, 1.0],
            'text-anchor': 'top'
          },
          paint: {
            'icon-opacity': ['interpolate', ['linear'], ['zoom'], 7.5, 0, 8.5, 1],
            'text-opacity': ['interpolate', ['linear'], ['zoom'], 7.5, 0, 8.5, 1]
          }
        },
        after: 'CUSTOM.AIRPORTS.GEOJSON'
      }
    ]
    this.layers = { sources, layers }
  },
  methods: {
    onLoadFeatures(features) {
      this.features = features
    }
  }
}
</script>

<style lang="scss"></style>

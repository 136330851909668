<template>
  <div id="nav-container">
    <div id="nav">
      <div @click="onClick('/airportsheliports')" :class="{ 'nav-active': $route.path === '/airportsheliports' }">
        Аэродромы вертодромы
      </div>
      <div @click="onClick('/localities')" :class="{ 'nav-active': $route.path === '/localities' }">
        Населённые пункты
      </div>
      <div @click="onClick('/')" class="nav-home">Обзорная<br />карта</div>
      <div @click="onClick('/stateborders')" :class="{ 'nav-active': $route.path === '/stateborders' }">
        Приграничные зоны
      </div>
      <div @click="onClick('/arnad')" :class="{ 'nav-active': $route.path === '/arnad' }">Арнад</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    onClick(path) {
      if (this.$route.path !== path) this.$router.push(path)
    }
  }
}
</script>

<style lang="scss">
#nav-container {
  position: fixed;
  bottom: 0;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;

  #nav {
    background: #3e5c76;
    pointer-events: all;
    display: flex;
    user-select: none;
    overflow: hidden;
    border-radius: 2px;
    opacity: 0.5;
    transition: 1500ms;

    &:hover {
      opacity: 1;
      transition: 150ms;
    }

    > div {
      width: 130px;
      color: #fff6;
      font-size: 12px;
      line-height: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      cursor: pointer;
      text-transform: uppercase;
      transition: 200ms;
      font-weight: 300;
      letter-spacing: 1px;

      &:hover {
        background: #fff1;
      }

      &:active {
        background: #fff2;
      }
    }

    .nav-home {
      font-size: 14px;
      line-height: 16px;
      color: #fffa;
    }

    .nav-active {
      color: #ef7a85;
    }
  }
}
</style>

<template>
  <div class="file-loader-geojson">
    <s-button :title="title" :loading="loading" @click="onClick" />
    <input ref="file" type="file" accept=".geojson" @change="loadFile" style="display: none" />
  </div>
</template>

<script>
import SButton from './SButton.vue'
export default {
  components: { SButton },
  props: {
    disabled: Boolean,
    title: {
      type: String,
      default: 'Загрузить'
    }
  },
  data() {
    return {
      loading: false
    }
  },
  mounted: function () {},
  methods: {
    onClick: function () {
      this.$refs.file.click()
    },
    loadFile: function (event) {
      const file = event.target.files[0]
      const reader = new FileReader()

      if (!file) return console.error('Не удалось загрузить файл')

      this.loading = true
      reader.readAsText(file)
      reader.onload = this.prepareDataFromFile
      event.target.value = ''
    },
    prepareDataFromFile: function (event) {
      this.$emit('input', JSON.parse(event.target.result))
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
.file-loader-geojson {
  display: flex;
}
</style>

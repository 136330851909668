<template>
  <div class="main">
    <map-container id="observe" :tile-layers="layers" />
    <g-navigation />
  </div>
</template>

<script>
import GNavigation from '@/components/GNavigation'
import MapContainer from '@/components/mapcontainer/MapContainer'

export default {
  name: 'Observe',
  components: {
    GNavigation,
    MapContainer
  },
  data() {
    return {
      selection: [],
      selectedFeature: undefined,
      layers: {}
    }
  },
  created() {
    const baseUrl = `${window.location.protocol}//${window.location.host}/airspace/tiles`
    const sources = [
      {
        name: 'airports',
        props: {
          type: 'vector',
          tiles: [`${baseUrl}/CUSTOM.AIRPORTS/{z}/{x}/{y}.pbf`],
          minzoom: 7,
          maxzoom: 12,
          volatile: true
        }
      },
      {
        name: 'heliports',
        props: {
          type: 'vector',
          tiles: [`${baseUrl}/CUSTOM.HELIPORTS/{z}/{x}/{y}.pbf`],
          minzoom: 7,
          maxzoom: 12,
          volatile: true
        }
      },
      {
        name: '5kmcircle',
        props: {
          type: 'vector',
          tiles: [`${baseUrl}/CUSTOM.RESTRICTIVE.AIRPORT_HELIPORT_5KM_CIRCLE/{z}/{x}/{y}.pbf`],
          minzoom: 8,
          maxzoom: 12,
          volatile: true
        }
      },
      {
        name: 'cta',
        props: {
          type: 'vector',
          tiles: [`${baseUrl}/ARNAD.RESTRICTIVE.CTA/{z}/{x}/{y}.pbf`],
          minzoom: 2,
          volatile: true
        }
      },
      {
        name: 'tma',
        props: {
          type: 'vector',
          tiles: [`${baseUrl}/ARNAD.RESTRICTIVE.TMA/{z}/{x}/{y}.pbf`],
          minzoom: 4,
          maxzoom: 9,
          volatile: true
        }
      },
      {
        name: 'ctr',
        props: {
          type: 'vector',
          tiles: [`${baseUrl}/ARNAD.RESTRICTIVE.CTR/{z}/{x}/{y}.pbf`],
          minzoom: 6,
          maxzoom: 9,
          volatile: true
        }
      },
      {
        name: 'd',
        props: {
          type: 'vector',
          tiles: [`${baseUrl}/ARNAD.RESTRICTIVE.D/{z}/{x}/{y}.pbf`],
          minzoom: 6,
          maxzoom: 12,
          volatile: true
        }
      },
      {
        name: 'p',
        props: {
          type: 'vector',
          tiles: [`${baseUrl}/ARNAD.RESTRICTIVE.P/{z}/{x}/{y}.pbf`],
          minzoom: 6,
          maxzoom: 12,
          volatile: true
        }
      },
      {
        name: 'r',
        props: {
          type: 'vector',
          tiles: [`${baseUrl}/ARNAD.RESTRICTIVE.R/{z}/{x}/{y}.pbf`],
          minzoom: 6,
          maxzoom: 12,
          volatile: true
        }
      },
      {
        name: 'nall',
        props: {
          type: 'vector',
          tiles: [`${baseUrl}/NOTAM.RESTRICTIVE.ALL/{z}/{x}/{y}.pbf`],
          minzoom: 6,
          maxzoom: 12,
          volatile: true
        }
      },
      {
        name: 'localities',
        props: {
          type: 'vector',
          tiles: [`${baseUrl}/CUSTOM.LOCALITIES/{z}/{x}/{y}.pbf`],
          minzoom: 3,
          maxzoom: 8,
          volatile: true
        }
      },
      {
        name: 'stateborderzones',
        props: {
          type: 'vector',
          tiles: [`${baseUrl}/CUSTOM.RESTRICTIVE.STATE_BORDER/{z}/{x}/{y}.pbf`],
          minzoom: 2,
          maxzoom: 12,
          volatile: true
        }
      }
    ]
    const layers = [
      {
        props: {
          id: 'CUSTOM.LOCALITIES-village-outline',
          type: 'line',
          source: 'localities',
          'source-layer': 'l',
          minzoom: 7,
          filter: ['==', ['get', 'fclass'], 'village'],
          paint: {
            'line-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0, 8, 0.5],
            'line-color': '#6b9080',
            'line-dasharray': [3, 1],
            'line-width': 1.3
          }
        }
      },
      {
        props: {
          id: 'CUSTOM.LOCALITIES-village-fill',
          type: 'fill',
          source: 'localities',
          'source-layer': 'l',
          minzoom: 7,
          filter: ['==', ['get', 'fclass'], 'village'],
          paint: {
            'fill-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0, 8, 0.1],
            'fill-color': '#6b9080'
          }
        },
        after: 'CUSTOM.LOCALITIES-village-outline'
      },
      {
        props: {
          id: 'CUSTOM.LOCALITIES-town-outline',
          type: 'line',
          source: 'localities',
          'source-layer': 'l',
          minzoom: 5,
          filter: ['==', ['get', 'fclass'], 'town'],
          paint: {
            'line-opacity': ['interpolate', ['linear'], ['zoom'], 5, 0, 7, 0.5],
            'line-color': '#3fa7d6',
            'line-dasharray': [3, 1],
            'line-width': 2
          }
        },
        after: 'CUSTOM.LOCALITIES-village-fill'
      },
      {
        props: {
          id: 'CUSTOM.LOCALITIES-town-fill',
          type: 'fill',
          source: 'localities',
          'source-layer': 'l',
          minzoom: 3,
          filter: ['==', ['get', 'fclass'], 'town'],
          paint: {
            'fill-opacity': ['interpolate', ['linear'], ['zoom'], 3, 0, 5, 0.1],
            'fill-color': '#3fa7d6'
          }
        },
        after: 'CUSTOM.LOCALITIES-town-outline'
      },
      {
        props: {
          id: 'CUSTOM.LOCALITIES-city-outline',
          type: 'line',
          source: 'localities',
          'source-layer': 'l',
          minzoom: 3,
          filter: ['==', ['get', 'fclass'], 'city'],
          paint: {
            'line-opacity': ['interpolate', ['linear'], ['zoom'], 3, 0, 5, 0.5],
            'line-color': '#985277',
            'line-dasharray': [3, 0.5],
            'line-width': 2
          }
        },
        after: 'CUSTOM.LOCALITIES-town-fill'
      },
      {
        props: {
          id: 'CUSTOM.LOCALITIES-city-fill',
          type: 'fill',
          source: 'localities',
          'source-layer': 'l',
          minzoom: 3,
          filter: ['==', ['get', 'fclass'], 'city'],
          paint: {
            'fill-opacity': ['interpolate', ['linear'], ['zoom'], 3, 0, 5, 0.1],
            'fill-color': '#985277'
          }
        },
        after: 'CUSTOM.LOCALITIES-city-outline'
      },
      {
        props: {
          id: 'CUSTOM.RESTRICTIVE.AIRPORT_HELIPORT_5KM_CIRCLE-outline',
          type: 'line',
          source: '5kmcircle',
          'source-layer': 'l',
          paint: {
            'line-color': '#999',
            'line-width': 2,
            'line-opacity': ['interpolate', ['linear'], ['zoom'], 8, 0, 9, 1]
          }
        },
        after: 'CUSTOM.LOCALITIES-city-fill'
      },
      {
        props: {
          maxzoom: 5,
          id: 'ARNAD.RESTRICTIVE.CTA',
          type: 'line',
          source: 'cta',
          'source-layer': 'l',
          paint: {
            'line-color': '#999',
            'line-width': 1.5,
            'line-opacity': ['interpolate', ['linear'], ['zoom'], 2, 0, 4, 1, 5, 0]
          }
        }
      },
      {
        props: {
          maxzoom: 7,
          id: 'ARNAD.RESTRICTIVE.TMA',
          type: 'line',
          source: 'tma',
          'source-layer': 'l',
          paint: {
            'line-color': '#4895EF',
            'line-width': 2,
            'line-opacity': ['interpolate', ['linear'], ['zoom'], 4, 0, 5.5, 1, 6, 1, 7, 0]
          }
        }
      },
      {
        props: {
          maxzoom: 9,
          id: 'ARNAD.RESTRICTIVE.CTR',
          type: 'line',
          source: 'ctr',
          'source-layer': 'l',
          paint: {
            'line-color': '#629677',
            'line-width': 2,
            'line-opacity': ['interpolate', ['linear'], ['zoom'], 6, 0, 7, 1, 8, 1, 8.5, 0]
          }
        }
      },
      {
        props: {
          id: 'ARNAD.RESTRICTIVE.D',
          type: 'fill',
          source: 'd',
          'source-layer': 'l',
          paint: {
            'fill-color': '#CA6702',
            'fill-outline-color': '#CA6702',
            'fill-opacity': ['interpolate', ['linear'], ['zoom'], 6, 0, 7, 0.2]
          }
        }
      },
      {
        props: {
          id: 'ARNAD.RESTRICTIVE.P',
          type: 'fill',
          source: 'p',
          'source-layer': 'l',
          paint: {
            'fill-color': '#9B2226',
            'fill-outline-color': '#9B2226',
            'fill-opacity': ['interpolate', ['linear'], ['zoom'], 6, 0, 7, 0.2]
          }
        }
      },
      {
        props: {
          id: 'ARNAD.RESTRICTIVE.R',
          type: 'fill',
          source: 'r',
          'source-layer': 'l',
          paint: {
            'fill-color': '#BB3E03',
            'fill-outline-color': '#BB3E03',
            'fill-opacity': ['interpolate', ['linear'], ['zoom'], 6, 0, 7, 0.2]
          }
        }
      },
      {
        props: {
          id: 'NOTAM.RESTRICTIVE.ALL',
          type: 'fill',
          source: 'nall',
          'source-layer': 'l',
          paint: {
            'fill-color': '#0B3E03',
            'fill-outline-color': '#0B3E03',
            'fill-opacity': ['interpolate', ['linear'], ['zoom'], 6, 0, 7, 0.2]
          }
        }
      },
      {
        props: {
          id: 'CUSTOM.RESTRICTIVE.STATE_BORDER',
          type: 'fill',
          source: 'stateborderzones',
          'source-layer': 'l',
          minzoom: 2,
          paint: {
            'fill-color': '#ef476f',
            'fill-opacity': ['interpolate', ['linear'], ['zoom'], 2, 0, 3, 0.2]
          }
        }
      },
      {
        props: {
          id: 'CUSTOM.AIRPORTS',
          type: 'symbol',
          source: 'airports',
          'source-layer': 'l',
          minzoom: 7,
          layout: {
            'symbol-z-order': 'viewport-y',
            'icon-image': 'airport-marker',
            'icon-size': 0.8,
            'text-field': ['get', 'name_ru'],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-size': 12,
            'text-offset': [0, 1.25],
            'text-anchor': 'top'
          },
          paint: {
            'icon-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0, 8, 1],
            'text-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0, 8, 1]
          }
        }
      },
      {
        props: {
          id: 'CUSTOM.HELIPORTS',
          type: 'symbol',
          source: 'heliports',
          'source-layer': 'l',
          minzoom: 7.5,
          layout: {
            'symbol-z-order': 'viewport-y',
            'icon-ignore-placement': true,
            'icon-image': 'heliport-marker',
            'icon-size': 0.4,
            'text-field': ['get', 'name_ru'],
            'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            'text-size': 10,
            'text-offset': [0, 1.0],
            'text-anchor': 'top'
          },
          paint: {
            'icon-opacity': ['interpolate', ['linear'], ['zoom'], 7.5, 0, 8.5, 1],
            'text-opacity': ['interpolate', ['linear'], ['zoom'], 7.5, 0, 8.5, 1]
          }
        },
        after: 'CUSTOM.AIRPORTS'
      }
    ]
    this.layers = { sources, layers }
  }
}
</script>

<style lang="scss"></style>

<template>
  <div class="map-container">
    <div
      :id="id"
      class="map"
      v-on:contextmenu="
        (e) => {
          e.preventDefault()
          e.stopPropagation()
        }
      "
    ></div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'

export default {
  name: 'map-container',
  props: {
    id: {
      type: String,
      default: 'observe'
    },
    tileLayers: Object,
    jsonLayers: Object,
    jsonFeatures: Object
  },
  data() {
    return {
      map: {},
      isMapReady: false
    }
  },
  watch: {
    jsonFeatures(v) {
      if (!v || !v.features) return
      const src = this.map.getSource(v.sourceName)
      if (src) src.setData(v.features)
    }
  },
  created: function () {
    // Сохранить координаты и масштаб в локальное хранилище
    window.addEventListener('beforeunload', () => {
      localStorage.setItem('map_center', JSON.stringify(this.map.getCenter()))
      localStorage.setItem('map_zoom', JSON.stringify(this.map.getZoom()))
    })
  },
  mounted: function () {
    mapboxgl.accessToken =
      'pk.eyJ1Ijoib2xhZHVzaGVrIiwiYSI6ImNrY2ZzdHpwbDBpN3IydXAwejhhN2lrOGQifQ.WzaR6fk4b6aDloXi4ssDFg'

    this.map = new mapboxgl.Map({
      style: 'mapbox://styles/mapbox/light-v10',
      center: JSON.parse(localStorage.getItem('map_center')) || [30.225854, 59.942618],
      zoom: JSON.parse(localStorage.getItem('map_zoom')) || 6,
      pitch: 0,
      bearing: 0,
      container: this.id,
      antialias: true
    })

    this.map.dragRotate.disable()
    this.map.touchZoomRotate.disableRotation()

    this.loadImage('./img/airport_filled_48x48.png', 'airport-marker')
    this.loadImage('./img/heliport_48x48.png', 'heliport-marker')

    this.map.on('load', () => {
      this.isMapReady = true
      this.addTileLayers()
      this.addJSONLayers()
    })

    // this.map.on('zoomend', () => {
    //   console.log(this.map.getZoom())
    // })
  },
  beforeDestroy() {
    this.map.remove()
  },
  methods: {
    addTileLayers() {
      if (!this.tileLayers) return

      if (!this.tileLayers.sources) return
      for (const src of this.tileLayers.sources) this.map.addSource(src.name, src.props)

      if (!this.tileLayers.layers) return
      for (const l of this.tileLayers.layers) this.map.addLayer(l.props, l.after)
    },
    addJSONLayers() {
      if (!this.jsonLayers) return

      if (!this.jsonLayers.sources) return
      for (const src of this.jsonLayers.sources) this.map.addSource(src.name, src.props)

      if (!this.jsonLayers.layers) return
      for (const l of this.jsonLayers.layers) this.map.addLayer(l.props, l.after)
    },
    loadImage(path, name) {
      this.map.loadImage(path, (error, image) => {
        if (error) throw error
        this.map.addImage(name, image)
      })
    },
    zoomTo: function (item) {
      const feature = item.feature
      const coords = feature.geometry.coordinates
      const bounds = !coords[0].length
        ? new mapboxgl.LngLatBounds([coords[0] - 0.05, coords[1] - 0.05], [coords[0] + 0.05, coords[1] + 0.05])
        : new mapboxgl.LngLatBounds(coords)
      this.map.fitBounds(bounds, {
        padding: 20
      })
    }
  }
}
</script>

<style lang="scss">
.map-container {
  z-index: 0;
  .map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: #e1e3e6;
  }
}
</style>

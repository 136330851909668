<template>
  <div id="authentication-page">
    <div id="auth-form">
      <span :class="{ nothing: !username && !lf }">Логин</span>
      <input ref="username" type="text" v-model="username" @focus="lf = true" @blur="lf = false" />
      <span :class="{ nothing: !password && !pf }">Пароль</span>
      <input ref="password" type="password" v-model="password" @focus="pf = true" @blur="pf = false" />
      <div id="auth-button" @click="onClick" :class="{ 'auth-loading': loading }">
        <div>Войти</div>
        <div v-if="loading" class="spinner"></div>
      </div>
      <div class="auth-err-descr" :style="{ visibility: err ? 'visible' : 'hidden' }">{{ err }}</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      lf: false,
      pf: false,
      loading: false,
      err: ''
    }
  },
  methods: {
    onClick() {
      if (!this.username) return this.$refs.username.focus()
      if (!this.password) return this.$refs.password.focus()

      const fetchParams = {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify({
          username: this.username,
          password: this.password
        }),
        timeout: 6000
      }

      this.loading = true
      return fetch('/backend/login', fetchParams)
        .then((response) => {
          if (!response.ok && response.status !== 400) throw Error(response.statusText)
          return response.json()
        })
        .then((response) => {
          if (response.generalResponse.status !== 'NOK') return response
          throw Error(response.generalResponse.exceptionDescription)
        })
        .then((response) => {
          this.$store.dispatch('setToken', response.token)
          this.$router.push('/')
        })
        .catch((error) => {
          console.log('Сервер не готов к сотрудничеству, увы', error)
          this.err = error
          setTimeout(() => {
            this.err = ''
          }, 3000)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
#authentication-page {
  width: 100vw;
  height: 100vh;
  background: #4f5d75;
  display: flex;
  justify-content: center;
  align-items: center;

  #auth-form {
    position: relative;

    display: flex;
    flex-direction: column;
    // padding: 16px;
    width: 250px;

    > span {
      margin: 8px 8px 0 8px;
      color: #fff3;
      font-weight: 300;
      letter-spacing: 1px;
      user-select: none;
      pointer-events: none;
      transition: 200ms;
    }

    .nothing {
      transform: translate(16px, 26px);
    }

    > input {
      margin: 8px;
      margin-top: 0;
      padding: 8px 16px;
      outline: none;
      border: none;
      background: none;
      border-bottom: 2px solid #fff5;
      color: #fffa;
      letter-spacing: 1px;
      text-overflow: ellipsis;
      transition: 200ms;

      &:hover {
        color: #e9b588;
      }

      &:focus {
        color: #e9b588;
        background: #fff1;
      }
    }

    .auth-loading {
      color: transparent;
      pointer-events: none;
      background: #fff1;

      > div {
        color: transparent;
      }
    }

    #auth-button {
      margin: 16px 8px 8px 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 16px 16px;
      color: #fff5;
      letter-spacing: 2px;
      user-select: none;
      cursor: pointer;
      transition: 200ms;

      &:hover {
        color: #e9b588;
        background: #fff1;
      }

      &:active {
        background: #fff2;
      }
    }

    .auth-err-descr {
      position: absolute;
      top: 100%;
      width: 203px;
      max-height: 120px;
      margin: 16px 8px;
      padding: 8px 16px;
      color: #fff9;
      letter-spacing: 1px;
      background: #6a040f;
      display: flex;
      text-overflow: ellipsis;
    }
  }
}
</style>

<template>
  <div class="flx_column_center cl_prim" :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    width: {
      type: [String, Number],
      default: 24
    },
    classes: String
  }
}
</script>

<style lang="scss">
.icon_base {
  svg {
    transition: transform 200ms;
  }
}
</style>

<template>
  <div class="button" :class="{ 'button-disabled': disabled || loading }">
    <div class="button-title" :style="{ visibility: !loading ? 'visible' : 'hidden' }" @click="$emit('click')">
      {{ title }}
    </div>
    <div v-if="loading" class="spinner"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    loading: Boolean,
    disabled: Boolean
  }
}
</script>

<style lang="scss">
.button {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  user-select: none;
  transition: 200ms;
  color: #fff5;

  &:hover {
    background: #fff1;
  }

  &:active {
    background: #fff2;
  }

  .button-title {
    grid-column: 1;
    grid-row: 1;
    cursor: pointer;
    padding: 4px 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: padding 200ms;
    z-index: 0;
    text-transform: uppercase;
  }
}
.button-disabled {
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}
</style>

<template>
  <transition name="user-message-popup-slide-fade">
    <div class="user-message-popup" v-if="text" @mouseenter="onMouseEnter" @mouseout="onMouseOut">{{ text }}</div>
  </transition>
</template>

<script>
import { bus } from '@/main'

export default {
  data() {
    return {
      text: '',
      timer: undefined
    }
  },
  created() {
    bus.$on('um', (txt) => {
      this.show(txt)
    })
  },
  methods: {
    onMouseEnter() {
      if (this.timer) clearTimeout(this.timer)
      this.timer = undefined
    },
    onMouseOut() {
      this.timer = setTimeout(() => {
        this.text = ''
        this.timer = undefined
      }, 3000)
    },
    show(txt) {
      this.text = txt
      this.timer = setTimeout(() => {
        this.text = ''
        this.timer = undefined
      }, 3000)
    }
  }
}
</script>

<style lang="scss">
.user-message-popup {
  position: fixed;
  right: 16px;
  top: 76px;

  z-index: 1000;
  max-width: 250px;
  max-height: 150px;
  background: #7a3233;

  border-radius: 4px;
  color: #fff6;
  font-size: 14px;
  line-height: 16px;
  font-weight: 300;

  padding: 16px 16px;
  letter-spacing: 2px;
}

.user-message-popup-slide-fade-enter-active {
  transition: all 0.2s 0.15s ease;
}
.user-message-popup-slide-fade-leave-active {
  transition: all 0.1s ease;
}
.user-message-popup-slide-fade-enter {
  transform: translateY(20px);
  opacity: 0 !important;
}
.user-message-popup-slide-fade-leave-to {
  transform: scale(1.05, 1.05);
  opacity: 0 !important;
}
</style>

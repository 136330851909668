import Vue from 'vue'
import VueRouter from 'vue-router'

import Auth from '../views/Auth.vue'
import Observe from '../views/Observe.vue'
import About from '../views/About.vue'
import AirportsHeliports from '../views/AirportsHeliports.vue'
import Localities from '../views/Localities.vue'
import StateBorders from '../views/StateBorders.vue'
import Arnad from '../views/Arnad.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Auth,
    meta: { auth: false }
  },
  {
    path: '/',
    name: 'Observe',
    component: Observe,
    meta: { auth: true }
  },
  {
    path: '/about',
    name: 'About',
    component: About,
    meta: { auth: false }
  },
  {
    path: '/airportsheliports',
    name: 'AirportsHeliports',
    component: AirportsHeliports,
    meta: { auth: true }
  },
  {
    path: '/localities',
    name: 'Localities',
    component: Localities,
    meta: { auth: true }
  },
  {
    path: '/stateborders',
    name: 'StateBorders',
    component: StateBorders,
    meta: { auth: true }
  },
  {
    path: '/arnad',
    name: 'Arnad',
    component: Arnad,
    meta: { auth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

import store from '@/store'
async function _checkToken(token) {
  if (!token) return false

  const fetchParams = {
    method: 'GET',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${token}`
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    timeout: 1000
  }

  const isValid = await fetch('/backend/checkauth', fetchParams)
    .then((response) => {
      if (!response.ok && response.status !== 400) throw Error(response.statusText)
      return response.json()
    })
    .then((response) => {
      if (response.generalResponse.status !== 'NOK') return Promise.resolve(true)
      throw Error(response.generalResponse.exceptionDescription)
    })
    .catch(() => {
      return Promise.resolve(false)
    })

  return isValid
}

router.beforeEach(async (to, from, next) => {
  const requireAuth = to.matched.some((record) => record.meta.auth)
  if (!requireAuth) return next()
  const isTokenValid = await _checkToken(store.getters.getToken)
  if (!isTokenValid) {
    if (from.path !== '/login') router.push('/login')
  } else next()
})

export default router

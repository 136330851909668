<template>
  <div
    v-if="data && token"
    class="sender-geojson"
    :class="{ 'sender-geojson-disabled': disabled || loading }"
    @click="onClick"
  >
    <icon-upload class="cl_sec" :style="{ visibility: !loading ? 'visible' : 'hidden' }" />
    <div v-if="loading" class="spinner"></div>
  </div>
</template>

<script>
import { bus } from '@/main'
import IconUpload from './icons/IconUpload.vue'

export default {
  components: {
    IconUpload
  },
  props: {
    disabled: Boolean,
    data: Object,
    url: String
  },
  data() {
    return {
      loading: false
    }
  },
  computed: {
    token() {
      return this.$store.getters.getToken
    }
  },
  methods: {
    onClick() {
      this.sendJSONDataToServer()
    },
    sendJSONDataToServer() {
      if (!this.data) return

      const fetchParams = {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.token}`
        },
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(this.data),
        timeout: 60000
      }

      this.loading = true
      return fetch(this.url, fetchParams)
        .then((response) => {
          if (!response.ok && response.status !== 403) throw Error(response.statusText)
          return response.json()
        })
        .then((response) => {
          if (
            response.generalResponse.status === 'NOK' &&
            response.generalResponse.exceptionDescription === 'Пользователь не авторизован'
          )
            return this.$router.push('/login')
          if (response.generalResponse.status === 'NOK') throw Error(response.generalResponse.exceptionDescription)
          return response
        })
        .catch((error) => {
          console.log('Сервер не готов к сотрудничеству, увы', error)
          bus.$emit('um', 'Что-то пошло не так!')
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">
.sender-geojson {
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    opacity: 0.5;
  }

  &:hover {
    background: #fff1;
  }

  &:active {
    background: #fff2;
  }
}

.sender-geojson-disabled {
  opacity: 0.5;
  user-select: none;
  pointer-events: none;
}
</style>

<template>
  <div class="main">
    <user-message />
    <map-container id="localities" :json-layers="layers" :json-features="featuresObject" />
    <div class="instruments">
      <div class="instruments-title">Границы<br />населённых пунктов</div>
      <div v-if="features" class="instruments-features-fetched">
        Загружено<br />{{ features.features.length }} объектов
      </div>
      <file-loader-geojson @input="onLoadFeatures" style="margin-left: auto" />
      <sender-geojson :data="features" url="/backend/replace/custom/localities" />
      <g-navigation />
    </div>
  </div>
</template>

<script>
import GNavigation from '@/components/GNavigation'
import MapContainer from '@/components/mapcontainer/MapContainer'
import FileLoaderGeojson from '@/components/FileLoaderGeojson'
import SenderGeojson from '../components/SenderGeojson.vue'
import UserMessage from '../components/UserMessage.vue'

export default {
  name: 'Localities',
  components: {
    GNavigation,
    MapContainer,
    FileLoaderGeojson,
    SenderGeojson,
    UserMessage
  },
  data() {
    return {
      selection: [],
      selectedFeature: undefined,
      layers: {},
      features: undefined,
      disable: false
    }
  },
  computed: {
    featuresObject() {
      return {
        sourceName: 'CUSTOM.LOCALITIES',
        features: this.features
      }
    }
  },
  created() {
    const sources = [
      {
        name: 'CUSTOM.LOCALITIES',
        props: {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: []
          }
        }
      }
    ]
    const layers = [
      {
        props: {
          id: 'CUSTOM.LOCALITIES-village-outline',
          type: 'line',
          source: 'CUSTOM.LOCALITIES',
          minzoom: 7,
          filter: ['==', ['get', 'fclass'], 'village'],
          paint: {
            'line-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0, 8, 0.5],
            'line-color': '#6b9080',
            'line-dasharray': [3, 1],
            'line-width': 1.3
          }
        }
      },
      {
        props: {
          id: 'CUSTOM.LOCALITIES-village-fill',
          type: 'fill',
          source: 'CUSTOM.LOCALITIES',
          minzoom: 7,
          filter: ['==', ['get', 'fclass'], 'village'],
          paint: {
            'fill-opacity': ['interpolate', ['linear'], ['zoom'], 7, 0, 8, 0.1],
            'fill-color': '#6b9080'
          }
        },
        after: 'CUSTOM.LOCALITIES-village-outline'
      },
      {
        props: {
          id: 'CUSTOM.LOCALITIES-town-outline',
          type: 'line',
          source: 'CUSTOM.LOCALITIES',
          minzoom: 5,
          filter: ['==', ['get', 'fclass'], 'town'],
          paint: {
            'line-opacity': ['interpolate', ['linear'], ['zoom'], 5, 0, 7, 0.5],
            'line-color': '#3fa7d6',
            'line-dasharray': [3, 1],
            'line-width': 2
          }
        },
        after: 'CUSTOM.LOCALITIES-village-fill'
      },
      {
        props: {
          id: 'CUSTOM.LOCALITIES-town-fill',
          type: 'fill',
          source: 'CUSTOM.LOCALITIES',
          minzoom: 3,
          filter: ['==', ['get', 'fclass'], 'town'],
          paint: {
            'fill-opacity': ['interpolate', ['linear'], ['zoom'], 3, 0, 5, 0.1],
            'fill-color': '#3fa7d6'
          }
        },
        after: 'CUSTOM.LOCALITIES-town-outline'
      },
      {
        props: {
          id: 'CUSTOM.LOCALITIES-city-outline',
          type: 'line',
          source: 'CUSTOM.LOCALITIES',
          minzoom: 3,
          filter: ['==', ['get', 'fclass'], 'city'],
          paint: {
            'line-opacity': ['interpolate', ['linear'], ['zoom'], 3, 0, 5, 0.5],
            'line-color': '#985277',
            'line-dasharray': [3, 0.5],
            'line-width': 2
          }
        },
        after: 'CUSTOM.LOCALITIES-town-fill'
      },
      {
        props: {
          id: 'CUSTOM.LOCALITIES-city-fill',
          type: 'fill',
          source: 'CUSTOM.LOCALITIES',
          minzoom: 3,
          filter: ['==', ['get', 'fclass'], 'city'],
          paint: {
            'fill-opacity': ['interpolate', ['linear'], ['zoom'], 3, 0, 5, 0.1],
            'fill-color': '#985277'
          }
        },
        after: 'CUSTOM.LOCALITIES-city-outline'
      }
    ]
    this.layers = { sources, layers }
  },
  methods: {
    onLoadFeatures(features) {
      this.features = features
    }
  }
}
</script>

<style lang="scss"></style>
